import React from "react"
import { graphql } from "gatsby"
import GatsbyImage from "gatsby-image"
import { Helmet } from "react-helmet"

export default function AboutVienna({ data: { page } }) {
  return (
    <>
      <Helmet>
        <title>About our Office</title>
      </Helmet>
      <div className="container">
        <GatsbyImage fluid={page.heroImage.fluid} />
        <h2 className="h1 text-center my-4">{page.heroTitle}</h2>
        <div
          className="text-block"
          dangerouslySetInnerHTML={{ __html: page.textBlock.md.html }}
        />
        <div className="gallery">
          {page.gallery.map(item => (
            <div className="gallery__item">
              <GatsbyImage fluid={item.fluid} />
            </div>
          ))}
        </div>
        <h2 className="h1 text-center my-4">{page.londonOfficeTitle}</h2>
        <div
          className="text-block"
          dangerouslySetInnerHTML={{ __html: page.textBlock2.md.html }}
        />
        <div className="gallery">
          {page.gallery2.map(item => (
            <div className="gallery__item">
              <GatsbyImage fluid={item.fluid} />
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export const query = graphql`
  {
    page: contentfulAboutPage {
      title
      heroTitle
      heroImage {
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      textBlock {
        md: childMarkdownRemark {
          html
        }
      }
      londonOfficeTitle
      gallery {
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      textBlock2 {
        md: childMarkdownRemark {
          html
        }
      }
      gallery2 {
        fluid {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
`
